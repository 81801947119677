<template>
  <b-card>
    <b-row>
      <b-col
        md="4"
        cols="12"
        class="text-center border-right-secondary"
      >
        <h5 class="fw-bolder p-50">
          ASDEP SPBE
        </h5>
        <b-row>
          <b-col
            md="6"
            class="border-right-secondary"
          >
            <h4 class="fw-bold mb-0">
              {{ summary.app.asdep }}/{{ summary.total_apps }}
            </h4>
            <p class="card-text font-small-3 mb-0">
              Aplikasi
            </p>
          </b-col>
          <b-col
            md="6"
          >
            <h4 class="fw-bold mb-0">
              {{ summary.infra.asdep }}/{{ summary.total_infras }}
            </h4>
            <p class="card-text font-small-3 mb-0">
              Infrastruktur
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md="4"
        cols="12"
        class="text-center border-right-secondary"
      >
        <h5 class="fw-bolder p-50">
          DIR. TAKEL
        </h5>
        <b-row>
          <b-col
            md="6"
            class="border-right-secondary"
          >
            <h4 class="fw-bold mb-0">
              {{ summary.app.takel }}/{{ summary.total_apps }}
            </h4>
            <p class="card-text font-small-3 mb-0">
              Aplikasi
            </p>
          </b-col>
          <b-col
            md="6"
          >
            <h4 class="fw-bold mb-0">
              {{ summary.infra.takel }}/{{ summary.total_infras }}
            </h4>
            <p class="card-text font-small-3 mb-0">
              Infrastruktur
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md="4"
        cols="12"
        class="text-center"
      >
        <h5 class="fw-bolder p-50">
          DIR. LAIP
        </h5>
        <b-row>
          <b-col
            md="6"
            class="border-right-secondary"
          >
            <h4 class="fw-bold mb-0">
              {{ summary.app.laip }}/{{ summary.total_apps }}
            </h4>
            <p class="card-text font-small-3 mb-0">
              Aplikasi
            </p>
          </b-col>
          <b-col
            md="6"
          >
            <h4 class="fw-bold mb-0">
              {{ summary.infra.laip }}/{{ summary.total_infras }}
            </h4>
            <p class="card-text font-small-3 mb-0">
              Infrastruktur
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      show: false,
      yearBudget: localStorage.getItem('tahunAnggaran'),
      summary: {
        app: {
          asdep: 0,
          laip: 0,
          takel: 0,
        },
        infra: {
          asdep: 0,
          laip: 0,
          takel: 0,
        },
        total_apps: 0,
        total_infras: 0,
      },
    }
  },
  mounted() {
    this.getSummary()
  },
  methods: {
    getSummary() {
      this.$http.get('/analytic/recommendation-summary', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: this.yearBudget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.summary = res.data.data
          }
        })
    },
  },
}
</script>
